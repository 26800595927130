.tableContainer {
    border-radius: 8px !important;
    margin-top: 24px;
    background: white;
}

*[dir="rtl"] .tableContainer .ant-pagination-item-link {
    rotate: 180deg;
}

.tableContainer .ant-empty-description {
    display: none;
}

.tableContainer th:first-of-type {
    border-top-left-radius: 10px !important;
}

.tableContainer th:last-of-type {
    border-top-right-radius: 10px !important;
}

.tableContainer .ant-table-container {
    overflow: auto;
    min-height: 300px;
}


.tableContainer thead .ant-table-cell {
    padding: 0 !important;
    padding-inline: 16px !important;
    padding-block: 18px 6px !important;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    background: white;

}

.tableContainer thead {
    white-space: nowrap;
}

.tableContainer tbody .ant-table-cell {
    padding: 12px 16px !important;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /*background: white;*/
}

.tableContainer .ant-table-cell::before {
    display: none !important;
}

.tableContainer .ant-table-thead .ant-table-cell {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1;

}


.tableContainer .ant-pagination {
    margin-block: 8px !important;
    /*display: flex;*/
    /*flex-direction: row-reverse !important;*/
    margin-inline: 8px;
}

.tableContainer .ant-pagination li, .tableContainer .ant-pagination li button {
    border-radius: 8px;

}

.tableContainer .ant-pagination li button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
}

.tableContainer .ant-spin-dot-item {
    background: var(--primaryColor);
}

.action {
    padding: 0;
    margin: 0;
    outline-color: transparent;
    background: transparent;
    border-radius: 100px;
    border: none;
}

.disableHeaders thead {
    visibility: hidden;
}

.disableHeaders table {
    margin-top: -41px;
}

.ant-table-placeholder {
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.ant-table-placeholder > .ant-table-cell {
    margin-inline: -16px !important;
    height: 100%;
}

.table-empty-placeholder {
    position: sticky;
    right: 0;
    width: fit-content;
    height: 200px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
    margin-inline-start: -80px;
}

*[dir="rtl"] .table-empty-placeholder {
    transform: translateX(150%);
    left: -50%;
    margin-inline-start: 50%;
}