:root {
    --primaryColor:  rgb(92,85,165);
    --secondaryColor: rgb(92,85,165);  }
.modalTitle{
    font-size: 20px;
    display: inline-block;
}



.rowPadding{
    padding: 10px 30px !important;
    align-items: flex-start !important;
    display: flex !important;
    justify-content: space-between !important;
}

.nextState , .archive{
    padding: 2px 10px ;
    border-radius: 5px;
}

.archive{
    background: #5C5F62;
    color: white;
}