:root {
    --primaryColor:  rgb(92,85,165);
    --secondaryColor: rgb(92,85,165);}

.statusPickup {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 10px;
    border-left: solid 20px #cee1ff;
    cursor: pointer;
}

.tooltips {
    position: relative;
    display: inline-block;
    padding: 10px;
}

.tooltips .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: #00000080;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    transition: width 1s;
    position: absolute;
    z-index: 1;
    bottom: 85%;
    left: 50%;
    /* margin-left: -60px; */
}

.tooltips .tooltiptext:hover {
    width: 220px;
}

.tooltips .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 0%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #00000080 transparent;
}

.tooltips:hover .tooltiptext {
    visibility: visible;
}

.statusTitle {
    color: black;
    font-weight: bold;
    height: 24px;
    font-size: 15px;
    margin-right: 25px;
    margin-top: 5px;
}

.fa-info-circle {
    color: #c0c0c0;
}

.statusNumber {
    font-weight: bold;
    font-size: 36px;
    line-height: 140%;
    color: black;
    margin-bottom: 0rem !important;
    margin-right: 25px;
    margin-top: 5px;
}

.statusPostponed {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 10px;
    border-left: solid 20px #ffe6b9;
    cursor: pointer;
}

.statusReturn {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 10px;
    border-left: solid 20px #ffbfbf;
    cursor: pointer;
}

.statusOnDelivery {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 10px;
    border-left: solid 20px #bfffda;
    cursor: pointer;
}

.title1 {
    font-style: normal;
    font-weight: 600;
    /* font-size: 12px; */
    line-height: 20px;
    /* identical to box height, or 167% */

    color: #272727;
}

.title2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    color: #5c5f62;
}

.reqTypeTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 140%;
    /* or 50px */

    text-transform: capitalize;

    color: #272727;
}

.cancel.filled, .cancel:focus.filled {
    background: #d82c0d;
    color: white;
}

.cancel,
.cancel:focus {
    color: #d82c0d;
    border: 1px solid #d82c0d !important;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: transparent;
    padding: 4px 15px;
    font-size: 14px;
    margin-inline-end: 13px;
    margin-bottom: 13px;
    font-weight: bold;
}

.cancel:hover {
    color: white;
    background-color: #d82c0d;
}

.confirm,
.confirm:focus {
    color: #fff !important;
    border: 1px solid #c0c0c0 !important;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--primaryColor);
    padding: 4px 30px;
    font-size: 14px;
    margin-bottom: 13px;
    font-weight: bold;
}

.confirm:hover {
    background-color: var(--primaryColor);
}

.confirm:disabled,
.confirmSeller:disabled {
    color: #fff !important;
    border: 1px solid #c0c0c0 !important;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #c0c0c0 !important;
    padding: 4px 30px;
    font-size: 14px;
}

.cancel:disabled {
    color: #c0c0c0 !important;
    border: 1px solid #c0c0c0 !important;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: transparent !important;
    padding: 4px 15px;
    font-size: 14px;
    margin-inline-end: 13px;
}

.statusCard {
    
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

.padding {
    
    padding: 20px 20px;
    
    transition: background-color 0.5s;
}

.pickConfirm {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 10px;
    border-left: solid 20px #126afc;
    cursor: pointer;
}

.statusInHub {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 10px;
    border-left: solid 20px #f8a306;
    cursor: pointer;
}

.statusReturned {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 10px;
    border-left: solid 20px #eb5757;
    cursor: pointer;
}

.statusDelivered {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 10px;
    border-left: solid 20px #27ae60;
    cursor: pointer;
}

.detailsCard {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    overflow-y: auto;
    /* width: 70%; */
    transition: all 0.4s;
    width: 80%;
    background: #fbfbfb;
    box-shadow: -2px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 15px 0px;
}

.deliveryRangeDetails {
    position: absolute;
    right: 100px;
    top: 50px;
    transition: all 0.4s;
    width: 30%;
    background: #fbfbfb;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.deliveryRangeDetails p {
    /* border-left: 4px solid #2C6ECB;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-bottom: 0px; */
    line-height: 2.2rem;
}

.prices-headers {
    color: #6d7175;
}

.prices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
}

.prices p {
    margin-bottom: 0px;
}

.CourierdetailsCard {
    position: absolute;
    right: 0px;
    top: 0px;
    /* width: 70%; */
    transition: all 0.4s;
    width: 40%;
    background: #fbfbfb;
    box-shadow: -2px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 15px 20px;
}

.detailsCard h3,
.CourierdetailsCard h3 {
    display: inline-block;
    font-weight: bold;
    text-transform: capitalize;
    color: #5c5f62;
    font-size: 36px;
}

.detailsCard span,
.CourierdetailsCard span {
    color: var(--primaryColor);
    font-weight: 600;
    text-transform: uppercase;
}

.detailsCard .fa-times {
    color: #c0c0c0;
    font-size: 13px;
}

.iconPadding {
    padding-left: 7px;
}

.sectionPadding {
    padding: 0px 15px;
}

.costSection {
    padding: 0px 20px 25px 40px;
    border-bottom: 1px solid #c4c4c4;
}

.section {
    padding: 20px 20px 20px 40px;
    border-bottom: 1px solid #c4c4c4;
}

.fa-phone {
    transform: rotate(95deg);
    padding-top: 10px;
}

.fa-times {
    cursor: pointer;
}

/* .example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-exit {
  opacity: 1;
}


.example-exit.example-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
} */
@media (max-width: 700px) {
    .detailsCard {
        width: 100%;
    }

    .costSection {
        padding: 0px 0px 25px 10px;
    }

    .section {
        padding: 20px;
    }
}



/*Help Card*/
.side-icon {
    color: #babfc3;
}

.desc-sm {
    color: #5c5f62;
    font-size: small;
}

.informationCard {
    background: #ebf9fc;
    border-radius: 8px;
    border: 1px solid #98c6cd;
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.informationCard .fa-info-circle:before {
    color: #00a0ac;
    margin: 10px;
}

.informationCard .informationCardTitle {
    font-size: 16px;
    font-weight: bold;
}

.informationCard .informationCardSubtitle {
    font-size: 14px;
}

.informationCard .item {
    font-size: 14px;
    color: #6d7175;
}

.informationCard li {
    color: #98c6cd;
}

.setting-card {
    border-radius: 8px;
    background: white;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.setting-icon {
    padding: 33px 44px 33px 16px;
}

/* .setting-icon .Polaris-Icon {
  padding: 17px;
  border-radius: 8px;
  background: #eeeeee;
} */

.setting-icon .Polaris-Icon svg {
    width: 20px;
    fill: #5c5f62;
    height: 20px;
}

.setting-card-title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #2c6ecb;
    margin-bottom: 0px;
}

.setting-card-title:hover {
    cursor: pointer;
}

.setting-card-description {
    font-size: 12px;
    color: #272727;
}

.org-card {
    width: 70%;
    padding: 20px;
    border-radius: 8px;
    margin: auto;
    background: white;
}

.org-card-title {
    font-size: 17px;
    color: #202223;
    font-weight: bold;
}

.justified-label {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

.justified-label p {
    color: #2c6ecb;
    cursor: pointer;
}

.verify-btn {
    position: absolute;
    bottom: 0px;
}

/*Help Card*/
.side-icon {
    color: #babfc3;
}

.desc-sm {
    color: #5c5f62;
    font-size: small;
}

/* security component cards*/
.card {
    width: 70%;
    margin: 20px auto 0px;
}

@media (max-width: 700px) {
    .card {
        width: 100%;
    }
}

.card-body,
.card-header,
.card-footer {
    text-align: start;
}

.card-header {
    font-size: 20px;
}

.card-footer {
    padding: 20px 1rem;
}

.update-password,
.remove-account,
.delete-org {
    padding: 8px 16px;
    border: 1px solid #babfc3;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.update-password {
    color: black;
    background: #ffffff;
}

.remove-account {
    color: white;
    background: linear-gradient(180deg, #babfc3 86.46%, #bbbfc3 100%);
}

.delete-org {
    color: white;
    background: #871703;
}

/* permissions cards*/

.permission {
    margin: 20px 0px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background: #ffffff;
    padding: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.activate,
.deactivate {
    padding: 8px 12px;
    margin: auto 0px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
    inset 0px -1px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: none;
}

.permission p {
    margin-bottom: 0px;
    font-weight: 100;
}

.activate {
    color: white;
    background: #008060;
}

.deactivate {
    color: black;
    border: 1px solid #babfc3;
    background: white;
}

.informationCard2 {
    background: #ebf9fc;
    border-radius: 8px;
    border: 1px solid #98c6cd;
    /* display: flex;
    flex-direction: row; */
    padding: 20px;
}

.informationCard2 .fa-info-circle:before {
    color: #00a0ac;
    margin: 10px;
}

.informationCard2 .informationCardTitle {
    font-size: 16px;
    font-weight: bold;
}

.informationCard2 .informationCardSubtitle {
    font-size: 14px;
}

.informationCard2 .item {
    font-size: 14px;
    color: #6d7175;
}

.informationCard2 li {
    color: #98c6cd;
}

/* plan card*/
.plan-header {
    color: #5c5f62;
    font-size: 18px;
    font-weight: 700;
    padding: 0px;
    margin-bottom: 0px;
}

.plan-name {
    color: var(--primaryColor);
    font-size: 9px;
    padding: 0px;
    letter-spacing: 1px;
}

.ant-slider-track {
    z-index: 1 !important;
    background-color: var(--primaryColor) !important;
}

.ant-slider-disabled .ant-slider-track {
    background-color: var(--primaryColor) !important;
}

.ant-slider-handle {
    display: none;
}

.ant-slider-rail {
    background-color: #c0c0c0 !important;
}

.ant-slider {
    margin: 0px !important;
}

.orders-row {
    display: contents;
}

.orders-count {
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.orders-count p {
    font-size: 12px;
    font-weight: bold;
    padding: 0px;
    margin-bottom: 0px;
}

.orders-count span {
    color: #2c6ecb;
    font-size: 10px;
}

/*wallet*/
.cardsMargin {
    margin: 0px 10%;
}
