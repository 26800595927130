.primary, .secondary, .primaryVariant {
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    height: unset !important;
    min-width: 64px !important;
    --color-botton-on: var(--color-onPrimary);
    gap: 4px;
    display: flex;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.2);
}

.iconEnd {
    flex-direction: row-reverse;
}

.primary, .primary:hover, .primary:active, .primary:focus {
    background: var(--primaryColor);
    color: white;
    fill: white;
    stroke: white;

}

.primary {
    border: none;
    padding: 6px 16px;
}

.primary:hover {
    filter: brightness(90%);
}

.primary:active {
    filter: brightness(95%);
}

.primary:focus {
    outline: 3px solid var(--primaryColor);
}

.primaryVariant {
    background: var(--color-button-variant);
    color: var(--color-botton);
    fill: var(--color-botton);
    stroke: var(--color-botton);

    border: none;
    padding: 6px 16px;
}

.primaryVariant:hover {
    background: var(--color-button-variant);
    color: var(--color-botton-hover);
    fill: var(--color-botton-hover);
    stroke: var(--color-botton-hover);
}

.primaryVariant:active {
    background: var(--color-button-variant);
    background: var(--color-botton-pressed);
}

.primaryVariant:focus {
    background: var(--color-button-variant);
    outline: 3px solid #9DC8E9;
}

.primary:disabled, .primary:disabled:hover {
    background: var(--color-botton);
    color: var(--color-botton-on);
    fill: var(--color-botton-on);
    stroke: var(--color-botton-on);
    opacity: 0.5;
}

.primaryVariant:disabled {
    background: var(--color-button-variant);
    color: var(--color-botton);
    fill: var(--color-botton-on);
    stroke: var(--color-botton-on);
    opacity: 0.5;
}


.secondary {
    background: transparent;
    color: #5F5F5F;
    fill: #5F5F5F;
    stroke: #5F5F5F;
    border-radius: 4px;
    padding: 5px 15px;
}

.secondary:hover, .secondary:active, .secondary:focus {
    background: transparent;
    fill: var(--primaryColor);
    stroke: var(--primaryColor);
    border-color: var(--primaryColor);
    color: var(--primaryColor);
}

.icon {
    border-radius: 50px;
    width: 30px;
    height: 30px;
    border: none;
    background: transparent;
}

.icon:hover {
    background: var(--color-primary-variant);
}


.icon svg {
    height: 10px;
    width: 10px;
}

.spinner {
    height: 21px;
    margin-top: 1px;
}

.primary .spinner i {
    background: white;
}

.captchaReload {
    height: 10px;
}

.primary:disabled, .secondary:disabled, .primaryVariant:disabled {
    pointer-events: none;
}