.container {
    background: #ffffff;
    padding: 16px;
    border-radius: 8px;
}

.actions {
    display: flex;
    gap: 12px;

}

.actions > * {
    flex: 1;
}


.commentSection {
    margin-block: 8px;
}

/*.commenting .confirmButton {*/
/*    width: 0px;*/
/*}*/

.commentSection > * {
    width: 100%;
}

.secondaryButton, .secondaryButton:focus, .secondaryButton:active {
    background: #FEC453;
}

.secondaryButton:hover {
    background: #e5b041;
}

.invoiceImage {
    width: 100%;
    margin-bottom: 24px;
}