.container {
    padding: 8px 16px;
    display: inline-block;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
    min-width: 400px;
    width: 100%;
    max-width: 470px;
}

.container > * {
    display: block;
}

.container header {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
}

.body {
    /*margin-top: 28px;*/
}

.actions {
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.body, .actions {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}

.actions {
    margin-bottom: 8px;
}

.centered {
    display: flex;
    justify-content: center;
    margin-block: 50px;
}

.container hr {
    margin-inline: -16px;
    border: none;
    margin-top: 8px;
    margin-bottom: 16px;
    color: #e4e5e7;
    opacity: 1;
}