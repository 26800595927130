:root {
  --primaryColor:  rgb(92,85,165);
  --secondaryColor: rgb(92,85,165);}



  .loginContainer{
    background-image: url('../images/bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f0f0;
}



.pageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    max-width: 400px;
    
}


.pageContainer .form_icon {
      color: var(--primaryColor);
}

.login-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    
}



.login-header .logo {
  height: 120px;
  margin-bottom: 10px; 
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1px;
}

.login-header .logo img {
  max-width:100%;
  height: 100px;
  display: block;
  margin: 0 auto;
}

.login-header .logo p{
  margin: 1px 0 0;
  color: var(--primaryColor);
  font-weight: bold;
  width: 100%; 
  box-sizing: border-box;
  font-family: Cairo, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
   
}

@media only screen and (max-width: 480px) {
  /* Adjust styles for screens smaller than 480px (e.g., iPhones) */
  .logo p {
      font-size: 14px; /* Adjust font size for smaller screens */
  }
}


.login-header button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: transparent;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 20px;
    border-radius: 100px;
    color: var(--primaryColor);
    width: 40px;
    height: 40px;
    font-family: Cairo, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    
}


.title {
  text-align: center;
  font-weight: bold;
  margin-bottom: unset !important;
  color: var(--primaryColor);
}
.subTitle {
  color: var(--primaryColor);
}
.subTitle2 {
  color: #828282;
  font-size: 12px;
  text-align: center;
}
.forgot {
  text-align: right !important;
  font-size: 13px;
}
.forgot a {
  color: #126afc !important;
}
.adminBtn,
.adminBtn span .adminBtn :hover {
  background-color: var(--primaryColor) !important;
  border-radius: 40px;
  width: 100%;
  border: unset !important;
}
.sellerBtn,
.sellerBtn span .sellerBtn :hover {
  background-color: var(--secondaryColor) !important;
  border-radius: 40px;
  width: 100%;
  border: unset !important;
}
.error,
.ant-form-item-explain.ant-form-item-explain-error {
  margin-bottom: 0px !important;
  color: #d82c0d;
}
/******************************confirmation code css**********/
.custom-styles {
  --ReactInputVerificationCode-itemWidth: 3.75rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center
}


.counter-text {
  margin-right: 20px;
}


.custom-styles .ReactInputVerificationCode__item {
  font-weight: 500;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: unset !important;
}

.custom-styles .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  background-color: #ebebeb;
  transition: background-color 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item:after {
  background-color: #898a8d !important;
}
.confirmMsg {
  margin-top: 200px;
  text-align: center;
}
.formsCol
{
  margin-top: 90px;
}


@media only screen and (max-width: 990px) {
  .confirmMsg {
    margin-top: 40px;
  }
}


/* password registeration*/
.secondForm{
  opacity: 0;
  z-index: 1;
  transition: clip-path 1s;
  height: 0px;
  /* width: 0px; */
overflow: hidden;
clip-path: polygon(0 1%, 0 0, 0 100%, 0% 100%);

}


.showForm {
  height: fit-content;
  position: relative;
  /* width: 100%; */
  background: #eeeeee;
  /* margin-top: -100%; */
  /* transform: translateY(-100%); */
  opacity: 1;
  clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);

}

.ant-spin-dot-item{
  background-color: white;
}