:root {
    --primaryColor:  rgb(92,85,165);
    --secondaryColor: rgb(92,85,165);}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer,
.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing {
    align-items: center !important;
    margin-left: 3px;
}

.ant-pagination {
    display: inline !important;
    float: right;
}

.ant-pagination-item {
    margin: 0px 8px !important;
}

.ant-pagination-item-active {
    border-color: var(--primaryColor);
}

.requestTable {
    position: relative;
    min-height: 700px;
    height: 700px;
    background: white;
    display: flex;
    border-radius: 8px;
    overflow-x: auto; /* Add horizontal scrollbar when needed */
    flex-direction: column;
    justify-content: flex-start; /* Align items at the top */
}

.requestTable .noDataDiv {
    text-align: center;
    display: flex;
    padding: 24px 32px;
    position: absolute;
    background: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

.requestTable .tableBodyContainer {
    flex: 1;
    overflow-y: auto;
  }
  
  .requestTable thead th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  
  .requestTableC Table {
    width: 100%;
    border-collapse: collapse;
  }
  
 



.requestTable1 {
    min-height: 400px;
    height: 400px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.tableHeader {
    padding: 20px 12px;
}

.tableHeader h6 {
    font-size: 20px;
    color: black;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 0px;
}

.branches-wallets .Polaris-Icon svg {
    width: 20px;
    fill: var(--primaryColor);
    height: 20px;
}

.sellers-wallets .Polaris-Icon svg {
    width: 20px;
    fill: var(--secondaryColor);
    height: 20px;
}

.noDataHead {
    font-weight: bold;
    font-size: 20px;
}

.noDataDiv p,
.noDataDiv1 p {
    color: #6d7175;
    font-size: 14px;
    font-weight: 300;
}




.noDataDiv {
    text-align: center;
    display: flex;
    padding: 24px 32px;
    position: absolute;
    background: white;
    justify-content: center;
    /* top: 0; */
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 400px;
}
.noDataDivAnt {
    text-align: center;
    display: flex;
    background: white;
    justify-content: center;
    /* top: 0; */
    align-items: center;
    padding-block: 16px 12px;
    flex-direction: column;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 60%;
    margin-inline: auto;
}

.noDataDiv1 {
    text-align: center;
    margin: auto;
    padding: 65px;
    height: 40%;
    display: flex;
    background: white;
    justify-content: center;
    /* top: 0; */
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 60%;
}

@media (max-width: 880px) {
    .noDataDiv,
    .noDataDiv1 {
        width: 300px;
    }
}

.reqTableContainer {
    background-color: white;
    box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
    border-radius: 8px;
}

.tableFilters {
    display: inline-block;
}

.tableFilters .btn1,
.tableFilters .btn1:focus {
    background-color: transparent !important;
    color: #202223 !important;
    border-color: #c0c0c0 !important;
    font-size: 14px;
    border-radius: 4px 0px 0px 4px;
    box-shadow: unset !important;
}

.tableFilters .btn2,
.tableFilters .btn2:focus {
    background-color: transparent !important;
    color: #202223 !important;
    border-color: #c0c0c0 !important;
    font-size: 14px;
    border-radius: 0px 4px 4px 0px;
    box-shadow: unset !important;
}

.tableFilters .btn3,
.tableFilters .btn3:focus {
    background-color: #fff !important;
    color: #5c5f62 !important;
    border-color: #c0c0c0 !important;
    font-size: 14px;
    border-radius: 4px 0px 0px 4px;
    box-shadow: unset !important;
}

.tableFilters .btn4,
.tableFilters .btn4:focus {
    background-color: #fff !important;
    color: #5c5f62 !important;
    border-color: #c0c0c0 !important;
    font-size: 14px;
    border-radius: 0px 4px 4px 0px;
    box-shadow: unset !important;
}

/**************search icon********/
.input-icons i {
    position: absolute;
    color: #5c5f62;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
}

.icon {
    padding: 10px;
    min-width: 40px;
}

.searchInput {
    width: 100%;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #c0c0c0;
    outline: unset !important;
}

.searchInput::placeholder {
    font-size: 14px !important;
}

/****************************************/
.requestTable {
    position: relative;
}


.requestTable table {
    overflow: auto;
}

.requestTable .MuiDataGrid-root .MuiDataGrid-columnSeparator {
    display: none !important;
}

.requestTable .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.5);
}

.cashBtn {
    background: #ffffff;
    width: 100px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: var(--primaryColor);
    font-weight: bold;
    font-size: 12px;
    padding: 4px 10px;
    text-transform: uppercase;
}

.onlineBtn {
    background: var(--primaryColor);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: white;
    font-weight: bold;
    width: 100px;
    font-size: 12px;
    padding: 4px 10px;
    text-transform: uppercase;
}

.tableID {
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 0px !important;
    line-height: 17px;
    color: #003ad2;
}

.paragraphMargin {
    margin-bottom: 0px !important;
}

.tableID:hover {
    text-decoration: underline;
}

.costTable {
    width: 100%;
}

.costTable th {
    color: #5c5f62;
    text-transform: uppercase;
    font-size: 14px;
}

.costTable p {
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: inline;
    padding: 5px;
    font-weight: bold;
    font-size: 18px;
}

.costTable td span {
    padding-left: 30px;
    font-weight: bold;
}

.priceSpan {
    color: var(--secondaryColor) !important;
    font-weight: bold;
}

.paymentMethod {
    color: var(--primaryColor);
    text-transform: uppercase;
    font-size: 14px !important;
    padding: 5px 25px !important;
}

.userIcons {
    color: #5c5f62;
    padding-right: 10px;
}

.phoneIcon {
    color: #5c5f62;
    font-size: 20px !important;
    font-weight: 800 !important;
}

.section h5 {
    text-transform: capitalize;
    color: #5c5f62;
    font-weight: bold;
    margin-bottom: 20px;
    display: inline;
    font-size: 22px;
}

.tableIcon {
    background-color: #ebebec;
    padding: 10px;
    width: 10%;
}

.tableIcon .fas {
    color: #5c5f62;
    font-size: 23px;
}

.tableInfo {
    text-transform: capitalize;
    color: #5c5f62;
    font-size: 13px;
    padding-left: 5px;
    border-bottom: solid 1px #c4c4c4;
}

.infoCard {
    background: #fbfbfb;
    box-shadow: -2px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-bottom: 20px;
    position: relative;
    margin-top: 40px;
}


.ticketTable {
    width: 100%;
}

.Modal {
    /* position: fixed;
    top: 30%;
    left: 25%; */
    transition: all 0.3s ease-out;
}

.ModalOpen {
    animation: openModal 0.4s ease-out forwards;
}

.ModalClosed {
    animation: closeModal 0.4s ease-out forwards;
}

@keyframes openModal {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes closeModal {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.ticketTable p {
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 5px;
    font-weight: bold;
    font-size: 14px;
}

.tableInfoPrice {
    color: #272727;
}

.productdesc {
    font-weight: bold;
    font-size: 11px !important;
    text-transform: uppercase;

    color: #d93846;
}

.deliveryCost {
    color: var(--secondaryColor) !important;
    font-weight: bold;
    margin-left: 15px;
}

.orderCost {
    color: var(--primaryColor);
    font-weight: bold;
    margin-left: 15px;
}

@media (max-width: 700px) {
    .costTable p {
        font-size: 14px;
    }

    .priceSpan,
    .costTable td span {
        padding-left: unset;
    }
}

.accepted {
    color: #27ae60 !important;
    border: 1px solid #27ae60;
    box-sizing: border-box;
    border-radius: 4px;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.9)
    ),
    #27ae60;
    padding: 2px 7px;
    font-size: 12px;
}

.rejected {
    font-size: 12px;
    color: #eb5757 !important;
    border: 1px solid #eb5757;
    box-sizing: border-box;
    border-radius: 4px;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.9)
    ),
    #eb5757;
    padding: 2px 7px;
    font-size: 12px;
}

.pending {
    font-size: 12px;
    color: #f8a306 !important;
    border: 1px solid #f8a306;
    box-sizing: border-box;
    border-radius: 4px;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.9)
    ),
    #f8a306;
    padding: 2px 7px;
    font-size: 12px;
}

.closed {
    font-size: 12px;
    color: #5c5f62 !important;
    border: 1px solid #5c5f62;
    box-sizing: border-box;
    border-radius: 4px;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.9)
    ),
    #5c5f62;
    padding: 2px 7px;
    font-size: 12px;
}

.PRS {
    border-color: #126afc;
    color: #126afc;
    background: #e6ecf5;
    font-size: 12px;
    padding: 4px 8px 4px 8px !important;
    border-radius: 4px;
}

.RRS {
    color: #eb5757;
    border-color: #eb5757;
    background: #f3e6e6;
    font-size: 12px;
    padding: 4px 8px 4px 8px !important;
    border-radius: 4px;
}

.ODRS {
    color: #27ae60;
    border-color: #27ae60;
    background: #d9ece1;
    font-size: 12px;
    padding: 4px 8px 4px 8px !important;
    border-radius: 4px;
}

.HRS {
    border-color: #f9d48d;
    color: #b67f15;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F3AA1C;
    font-size: 12px;
    padding: 4px 8px 4px 8px !important;
    border-radius: 4px;
}

.rs .MuiToolbar-root .MuiButtonBase-root {
    display: inline-block;
}

.MuiDataGrid-root .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
    visibility: hidden !important;
}

.userTableIcon {
    background: #e1e1e1;
    border: 1px solid #acb1b6;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
}

.userTableIconActive:hover {
    color: var(--primaryColor);
}

.userTableIconActive {
    color: #515456;
}

.userTableIconInActive {
    color: #ABABAB;
}

.userTableIconInActive:hover {
    cursor: not-allowed;
}

.userTableIcon:disabled {
    background: red !important;
    cursor: not-allowed !important;
}

.closeInfoModel {
    color: #c0c0c0;
    font-size: 20px;
    cursor: pointer;
    z-index: 100000;
}

@media (min-width: 600px) {
    .MuiToolbar-regular {
        min-height: 22px !important;
    }
}

.deliveryRangeDetails .ant-collapse-content > .ant-collapse-content-box {
    padding: 5px !important;
}

.headersItems {
    background: #f2f7fe;
    border-radius: 4px;
    margin-bottom: 0.5rem !important;
}

.deliveryRangeDetails
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header {
    color: #6d7175 !important;
}

.debit,
.credit,
.amount {
    margin-top: 10px;
    font-weight: 600;
    white-space: nowrap;
}

.debit {
    color: #871703;
}

.credit {
    color: var(--primaryColor);
}

.infoCardHead {
    border-radius: 5px 5px 0px 0px;
    padding: 8px;
    position: absolute;
    left: 0px;
    right: 45px;
    top: -40px;
}


.infoCardHead button {
    color: #7a7a7a;
    box-shadow: 0px 4px 6px rgba(206, 224, 255, 0.2);
    border-radius: 4px;
    font-size: 11px;
    padding: 5px;
    border: unset
}

.print-ticket {
    position: absolute;
    top: -40px;
    right: 0px;
    padding: 9px 13px;
    background: #ebebec;
    transition: all 1s;
}

.print-ticket:hover {
    cursor: pointer;
    background: #7d7d7d;
}


/* close runsheet */
