.form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 26px;
}


.form > .buttons {
    display: flex;
    gap: 8px;
    align-items: end;
    margin-bottom: 8px;
}

.buttons > button {
    min-width: 120px !important;
}

.reportContainer {
    background: white;
    border-radius: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 40px;
    margin-block: 20px;
}

.reportContainer > * {
    overflow: auto;
}


.paddingSection {
    padding-inline: 40px;

}
.reportsBody {
    max-width: 777px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0px 24px;
    justify-content: center;
}

.reportsContainer {
    margin-top: 55px;
}

@media screen and (max-width: 1200px) {
    .form {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media screen and (max-width: 900px) {
    .form {
        grid-template-columns: repeat(1, 1fr);

    }
}