:root {
    --primaryColor: rgb(92, 85, 165);
    --secondaryColor: rgb(92, 85, 165);
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    position: unset !important;
}

.MuiListItemText-root {
    color: #202223 !important;
    font-size: 14px !important;
    text-align: start;
    vertical-align: Center;
    font-weight: 500;
}

.MuiPaper-root {
    color: black !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.MuiDrawer-paper {
    top: 130px !important;
    /* height: 82vh !important; */
    position: absolute !important;
    background-color: rgb(246, 246, 247) !important;
    border: none !important;
}

@media (max-width: 599px) {
    .MuiDrawer-paper {
        top: 0px !important;
        height: 100% !important;
    }
}

.MuiListItemText-primary {
    color: #272727 !important;
}

.css-cveggr-MuiListItemIcon-root {
    color: #5c5f62 !important;
}

.MuiButtonBase-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.general {
    font-size: 12px;
    line-height: 16px;
    padding: 20px 20px 8px;
    text-transform: uppercase;
    margin-top: 20px;
    color: #6d7175;
}

.MuiTypography-root {
    font-weight: 600 !important;
    font-size: 15px !important;
}

/* .MuiCollapse-wrapperInner a{
  padding-inline-start:  55px;
} */
@media (min-width: 600px) {
    .MuiToolbar-root .MuiButtonBase-root {
        display: none;
    }
}

.MuiDrawer-paperAnchorRight {
    right: 0px !important;
    left: unset !important;
}

.subList .MuiTypography-body1 {
    margin-inline-start: 60px !important;
    color: #6d7175 !important;
    /* font-family: SF Pro Text Medium; */
}


.activeLink .css-cveggr-MuiListItemIcon-root,
.activeLink .MuiListItemText-primary {
    color: var(--primaryColor) !important;
    transition: all 0.5s;
}



.sidemenu .MuiButtonBase-root {
    padding-inline-start: 15px !important;
}

.activeLink .MuiButtonBase-root {
    background-color: #edeeef !important;
    border-inline-start: solid 6px var(--primaryColor);
}

.sidemenu .MuiSvgIcon-root {
    display: none !important;
}

.sidemenu .Polaris-Icon svg {
    width: 20px;
    fill: #5c5f62;
    height: 20px;
}

.activeLink .Polaris-Icon svg {
    fill: var(--primaryColor);
}

.sellerActiveLink .Polaris-Icon svg {
    fill: var(--secondaryColor);
}

.sellerActiveLink .css-cveggr-MuiListItemIcon-root,
.sellerActiveLink .MuiListItemText-primary {
    color: var(--secondaryColor) !important;
}

.sellerActiveLink .MuiButtonBase-root {
    background-color: #edeeef !important;
    border-left: solid 6px var(--secondaryColor);
}
