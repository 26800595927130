.container {
}

.title {
    display: flex;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;
}

.title h2:first-child {
    margin-inline-end: 86px;
}

.tag {
    padding: 6px 13px;
    border-radius: 100px;
    color: #202223;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    background: var(--primaryColor);
}

.id, .price {
    font-weight: 400;
    font-size: 22px;
}

.id {
    color: #5C5F62;
}

.price span {
    color: #148060;
    font-weight: 500;
}

.title h4 {margin-bottom: 1px}

.price span[aria-label="negative"] {
    color: #871703;
}

.collapse {
    background: transparent;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10%;
}

.panel {
    background: white;
    border-radius: 7px;
}

.panel ul {
    list-style-type: none !important;
    margin: 0;
    padding: 0;
}

.panel div[role="button"] {
    color: #272727;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    border-radius: 7px;
}

.panel div[role="button"][aria-expanded=true] {
    border-radius: 7px;
    margin-bottom: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}