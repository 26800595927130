@import '~antd/dist/antd.css';

:root {
    --primaryColor: rgb(92, 85, 165);
    --secondaryColor: rgb(92, 85, 165);
}

@font-face {
    font-family: SF Pro Text;
    src: url("./fonts/SF-Pro-Text-Regular.otf");
}

@font-face {
    font-family: SF Pro Text;
    src: url("./fonts/SF-Pro-Text-Bold.otf");
    font-weight: bold;
}

@font-face {
    font-family: SF Pro Text Medium;
    src: url("./fonts/SF-Pro-Text-Medium.otf");
}

.preline {
    white-space: pre-line;
}

.MuiDataGrid-root {
    font-family: unset !important;
}

.MuiTypography-body1 {
    font-family: unset !important;
}

.App,
body {
    background-color: #eeeeee !important;
    height: 100%;
    width: 100%;
    font-family: SF Pro Text !important;

}

.header .nav-link {
    padding-inline-start: 1rem;
    padding-inline-end: 0rem;
    text-align: end;
}

.modal,
.modal-backdrop {
    z-index: 10000000
}

.header .Polaris-Icon {
    position: absolute;
}

.header .Polaris-Icon svg {
    width: 30px;
    fill: var(--primaryColor);
    height: 30px;
    cursor: pointer;
}

.AppAr,
body {
    background-color: #eeeeee !important;
    height: 100%;
    width: 100%;
    font-family: 'Cairo', sans-serif !important;
}


.changeStatusSelect .ant-select-selector {
    background: var(--primaryColor) !important;
    color: black !important;
    font-weight: bold !important;
    border-radius: 4px !important;
}

.pageContainer {
    padding: 50px;
    /* background-color: #fff; */
}

.pageContainer i {
    color: var(--primaryColor);
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    text-align: start !important;
}

.pagePadding {
    padding-inline-start: 300px;
    padding-inline-end: 45px;
    padding-block: 40px;
}

.header {
    padding: 20px;
    padding-inline-end: 50px;


}

.dropdown-menu[data-bs-popper] {
    left: -35px !important;
}

.profileImg {
    width: 40px;
    height: 40px;
    border: solid 2px var(--primaryColor);
    border-radius: 50%;
}

.headerDropDown,
.header .dropdown-toggle,
.header .dropdown-toggle:active {
    /* background-color: transparent !important; */
    display: inline-block !important;
    color: black !important;
    font-weight: 600;
    border: unset !important;
    box-shadow: unset !important;
    margin-inline-end: 19px;
}

.header .dropdown-toggle::after {
    content: "";
    fill: red;
    border-top: unset !important;
    vertical-align: unset !important;
}

.dropdown-menu {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
        0 9px 28px 8px #0000000d;
    border: unset !important;
}

.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
    height: 30px !important;
    border: 1px solid #babfc3 !important;
}


.ant-pagination-simple .ant-pagination-simple-pager input {
    width: 25px !important;
    border: none !important;
    margin: 0px !important;
}

.ant-pagination-simple .ant-pagination-simple-pager {
    height: 30px !important;
}

.ant-pagination-slash {
    visibility: hidden !important;
    margin: 0 10px 0 0px !important;
}

.ant-pagination-prev,
.ant-pagination-jump-prev {
    margin: 0px !important;
}

span.ant-pagination-slash::after {
    content: "of";
    visibility: visible;
    margin: 0 !important;
}

@media only screen and (max-width: 600px) {

    .pageContainer,
    .pagePadding {
        padding: 10px;
    }


    /*.logo {*/
    /*    margin-inline-start: 20px;*/
    /*}*/
    .MuiToolbar-root button {
        padding: 0px !important;
        margin: 0px !important;
    }
}

.form-check-input {
    border-color: var(--primaryColor);
    outline: none !important;
    box-shadow: none !important;
}

.form-check-input:checked {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
}

.over-select-link-en {
    position: absolute;
    top: 35px;
    z-index: 10;
    right: 40px;
    font-weight: 400;
}

.over-select-link-ar {
    position: absolute;
    top: 35px;
    z-index: 10;
    left: 40px;
    font-weight: 400;
}

/*********************************************************/
.customercreateCard {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 30px;
}

.addBtn {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;

    color: #2c6ecb;
}

.tabsTitles {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;

    text-transform: uppercase;
    line-height: 11px;
    /* identical to box height, or 93% */

    letter-spacing: 0.07px;
    color: #5c5f62;
}

/* .ant-select-item-option,
.ant-select-item-option-content {
  z-index: 100000000000 !important;
} */
.ant-modal-footer {
    display: none !important;
    color: #5c5f62;
}

.confirm-check {
    width: 50px;
    display: block;
    margin: 25px auto 0;
}

.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
}

.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
}

.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
}

.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

.btnPosition {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px;
}

.btnPosition button {
    background: var(--primaryColor);
    color: white;
    font-size: 14px;
    border: none;
    padding: 8px 10px;
    border-radius: 4px;
}

.btnPosition .cancelBtn {
    font-weight: bold;
}

.btnPosition .cancelBtn:disabled {
    background: transparent;
    color: #c0c0c0;
    border: 1px solid #c0c0c0;
}

@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }

    100% {
        stroke-dashoffset: 900;
    }
}

@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }

    100% {
        stroke-dashoffset: 900;
    }
}

.text-end .row {
    justify-content: flex-end !important;
}

.text-end {
    text-align: end !important;
}

.ant-form-item {
    margin-bottom: 8px !important;
}

.doneBtn {
    width: 112px;
    cursor: pointer;
}

.PhoneInputInput {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*reports*/

.search-btn {
    color: #fff !important;
    border: 1px solid #c0c0c0 !important;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--primaryColor);
    padding: 4px 10px;
    font-size: 14px;
    margin-right: 13px;
}

.search-btn svg {
    width: 16px;
    fill: white;
}

.reports-filter {
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    background: white;
    padding: 8px 8px 14px;
    border-radius: 8px;
}

.reports-filter .date {
    color: #202223;
    font-size: 14px;
    padding: 5px;
    margin-top: 4px;
    background: #e4e5e7;
}

.stateList {
    border-inline-start: #2c6ecb solid 3px;
    border-radius: 0px 4px 4px 0px;
}

.chart {
    background: white;
    border-radius: 8px;
    width: 100%;
    height: auto;
    padding: 15px;
    margin-top: 25px;
}

.chart .chartTitle {
    font-weight: bold;
}

.chart .chartSubtitle {
    letter-spacing: 0.25px;
    color: #5c5f62;
}

#card-actions-menu .MuiList-root.MuiMenu-list.MuiList-padding {
    background-color: white !important;
}

.updatePassDiv {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 25px;
}

/* wallets */

.apply {
    color: #fff !important;
    border: 1px solid #c0c0c0 !important;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--primaryColor);
    padding: 0px 15px 0px 5px;
    font-size: 14px;
    margin-right: 13px;
}

.pay {
    color: #fff !important;
    border: 1px solid #c0c0c0 !important;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--primaryColor);
    padding: 5px 15px;
    font-size: 14px;
    margin-right: 13px;
}

.pay:disabled {
    background-color: #c0c0c0 !important;
}

.column-display {
    display: flex;
    justify-content: space-between;
}

.type {
    border-radius: 4px !important;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
}

.totalPrice {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background: white;
    padding: 8px;
}

.totalPriceLabel {
    font-weight: bold !important;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    color: #5c5f62;
}

.totalAmount {
    font-size: 24px;
    line-height: 24px;
    color: #202223;
    font-weight: bold !important;
}

.amountDisplay {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.amountDisplay input {
    color: var(--primaryColor);
    font-weight: bold;
    font-size: 18px;
    width: 40%;
    height: 30px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.ticketDetailsID {
    margin-bottom: 0rem;
    color: #7a7a7a;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.ticketLogo {
    filter: grayscale(100%);
}

.titlesBold {
    font-weight: 600;
}

.ticketPrintCard {
    border-bottom: solid 2px black;
    border-top: solid 2px black;
    border-style: dashed;
}

/*****************************seller******************************/
.form-check-seller {
    border-color: var(--secondaryColor) !important;
    outline: none !important;
    box-shadow: none !important;
}

.form-check-seller:checked {
    background-color: var(--secondaryColor) !important;
    border-color: var(--secondaryColor);
}

.SellerprofileImg {
    width: 40px;
    height: 40px;
    border: solid 2px var(--secondaryColor);
    border-radius: 50%;
}

.confirmSeller {
    color: #fff !important;
    border: 1px solid #c0c0c0 !important;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--secondaryColor);
    padding: 4px 30px;
    font-size: 14px;
    margin-right: 13px;
    font-weight: bold;
}

.ant-col.ant-form-item-label {
    text-align: start !important;
}

.modal-header .btn-close {
    margin: unset !important;
}

.text-end .ant-select-selection-placeholder {
    color: white;
}

.ant-modal-close {
    right: unset !important;
}

/* domain registeration */

.navbar-brand {
    margin-right: unset !important;
}

.MuiDataGrid-viewport {
    /* max-width: unset !important; */
}

/*registeration*/

.overlayModal {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #00000080;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.registration-spinner .anticon-spin {
    font-size: 15em !important;
    color: var(--primaryColor) !important;
    margin-bottom: 20px;
}

.overlayModal p {
    color: white;

}

.register-card {
    background: white;
    width: 70%;
    border-radius: 15px;
    padding: 2em 5em 2em 3em;
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;

}

.number-input {
    -moz-appearance: textfield;
}

.form-background {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;
}

.field-wrapper {
    display: flex;
    margin: 15px 15px 5px 15px;
    align-items: center;
    justify-content: center;
}

.form-title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}


.field-wrapper i {
    margin: 0px 20px 0px 10px;
}

.overlay-enter {
    opacity: 0.01;
}

.overlay-enter.overlay-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.overlay-leave {
    opacity: 1;
}

.overlay-leave.overlay-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.shipFees {
    box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
    border: none;
    padding: 5px;
    border-radius: 4px;
    font-weight: bold;
    width: 100px;
    height: 29px;
}

.afterInput::after {
    content: "EGP";
    position: absolute;
    right: 5px;
    top: 3px;
}

.afterInput,
.afterInputAr {
    position: relative;
}


.afterInputAr::after {
    content: "جنيه";
    position: absolute;
    left: 5px;
    top: 3px;
}


@media (max-width: 575px) {
    .register-card {
        background: white;
        width: 90%;
        border-radius: 15px;
        padding: 1em;
    }

    .form-background {
        height: unset;
        margin: 20px 0px;
    }

    .header {
        padding: 32px;
    }
}


tbody::before {
    content: "";
    display: block;
    height: 8px;
}

.infoCard .Polaris-Icon svg {
    width: 20px;
    fill: #5c5f62;
    height: 20px;
}


.text-end .Polaris-Icon svg {
    width: 20px;
    fill: #5c5f62;
    height: 20px;
    margin-inline-end: 20px;
}

.confirm2 {
    color: #fff !important;
    border: 1px solid #c0c0c0 !important;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--primaryColor);
    padding: 4px 30px;
    font-size: 14px;
    font-weight: bold;
}

.fa-search {
    cursor: pointer;
}

.MuiSvgIcon-fontSizeSmall {
    display: none !important;
}

.reports .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    background: black;
}

.changeStatusSelect {
    margin-bottom: 10px !important;
    width: 165px;
    border-radius: 4px;
    background: var(--primaryColor);
}

.ant-slider-handle:focus {
    border-color: var(--primaryColor);
    outline: none;
    box-shadow: 0 0 0 5px rgb(24 144 255 / 12%);
}

.payments .ant-slider-handle {
    display: unset !important;
    border: solid 2px var(--primaryColor) !important;
    width: 24px !important;
    height: 24px !important;
    z-index: 10;
}

.payments .ant-slider-handle.ant-tooltip-open {
    border-color: var(--primaryColor) !important;
}

.payments .ant-slider-rail {
    height: 15px !important;
    border-radius: 15px !important;
}


.payments .ant-slider-track {
    height: 14px !important;
    border-radius: 14px !important;
}

.ant-spin {
    color: var(--primaryColor) !important;
}

.spinnerAnt {
    align-items: center;
    display: flex;
    justify-content: center;
}

.spinnerAnt .ant-spin-dot {
    font-size: 305px !important;
}

.uploaderCard {
    margin-top: 20px;
    min-width: 400px;
    width: calc(var(--dimenSchemaHomeHeight) * 1.5);
    min-height: var(--dimenMinSchemaHomeHeight);
    height: calc(var(--dimenSchemaHomeHeight) - 100px);
    background-color: #D7E7DB;
    border-radius: 8px;
    border: 2px dashed #8C9196;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
    transition: background-color 2s, all 400ms ease;
    overflow: hidden;
    padding: 40px 0px;
}

.uploader-logo {
    border: 2px dashed #8C9196;
    background: #ffffff;
    margin-top: 20px;
    min-width: 200px;
    width: 250px;
    height: 250px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
    transition: background-color 2s, all 400ms ease;
    overflow: hidden;
    padding: 40px 0px;
}

.uploaderInput:hover {
    cursor: pointer;
}

.uploaderInput {
    position: absolute;
    /* width: 80ch; */
    height: 100px;
    opacity: 0;
}


.uploaderCard .uploadIcon,
.uploader-logo .uploadIcon {
    height: 40px;
    width: 40px;
    margin: 25px;
}

.uploaderCard h6 {
    font-weight: 600;
    color: #5c5f62;
}

.uploaderCard svg,
.uploader-logo svg {
    width: 50px;
    height: 70px;
    fill: #5c5f62;
}

.uploaderCard:hover {
    background-color: #0002;
}

.MuiMenu-list {
    background: white !important;
}

.bulk-upload-title,
.bulk-upload-sub {
    font-size: 14px;
    color: #6D7175;
    margin-bottom: 0px;
}

.titleDisplay {
    display: flex;
    align-items: center;
}

.back svg {
    width: 25px;
    margin-inline-end: 5px;
    fill: #5c5f62;
}

.error-bg {
    border: 1px solid #D82C0D;
    background-color: #FFE9E8;
}

.error-icon svg {
    width: 25px;
    margin-inline-end: 5px;
    fill: #D82C0D;
}

.css-h4y409-MuiList-root {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.footer-section {
    text-align: center;

}

.uploadLogo {
    font-size: 24px;
    margin-top: 30px;
    color: #000000B2;
}

.MuiDropzonePreviewList-removeButton {
    right: 40px !important;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.row-centered-vertical,
.row-centered-horizontal,
.row-centered {
    display: flex;
    flex-direction: row;
}

.column-centered-vertical,
.column-centered-horizontal,
.column-centered {
    display: flex;
    flex-direction: column;
}

.row-centered {
    justify-content: center;
    align-items: center;
}

.row-centered-vertical {
    justify-content: center;
}

.row-centered-horizontal {
    align-items: center;
}

.column-centered {
    justify-content: center;
    align-items: center;
}

.column-centered-horizontal {
    justify-content: center;
}

.column-centered-vertical {
    align-items: center;
}

.ant-select-disabled {
    background: #e9ecef !important;
}

.actionRow {
    display: flex;
    gap: 8px;
}

.actionRow a,
.actionRow:hover a {
    color: inherit;
}

.actionRow button {
    background: transparent;
    border: none;
    outline: none;
}

.login-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login-header button {
    background: transparent;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 20px;
    border-radius: 100px;
    color: var(--primaryColor);
    width: 50px;
    height: 50px;
    font-family: Cairo, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}

.navbar-brand {
    display: flex;
    max-height: 100px;
}

.navbar-brand img {
    height: 100%;
}

.grid-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 26px;
}

.grid-form.small-gap {
    gap: 8px 26px;
}

.drag-row {
    position: absolute;
    z-index: 10;
}


.marginless-label-item .ant-form-item-label {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}


.ant-input-disabled.lighter {
    color: #272727;
    background: white;
}


@media screen and (max-width: 1000px) {
    .grid-form {
        grid-template-columns: repeat(1, 1fr);

    }
}


.loginContainer .logo {
    height: 140px;
}

.block {
    display: block;
}

*[dir="rtl"] .ant-pagination-item-link svg {
    rotate: 180deg;
}

.header-extra {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-inline-start: 24px;
    cursor: default;
}

.header-links {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    font-size: 16px;

}

.header-links>a,
.header-wallet-number>a {
    color: var(--primaryColor) !important;
}

.header-links>a:hover,
.header-wallet-number>a:hover {
    text-decoration: underline;
}

.header-wallet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: default;
    margin-inline-start: auto;
    padding-inline: 24px;
}

.header-wallet-number {
    font-size: 16px;
    color: #5c5f62;
    display: flex;
    gap: 16px;
}

@media screen and (max-width: 600px) {
    .header {
        margin-bottom: 40px;
    }

    .header-welcome {
        display: none;
    }

    .header-links {
        flex-direction: column;
        gap: 2px;
    }

    .header-extra,
    .header-wallet {
        position: absolute;
        top: 128px;
    }

    *[dir="ltr"] .header-extra {
        left: 20px;
    }

    *[dir="rtl"] .header-extra {
        right: 20px !important;
    }

    *[dir="ltr"] .header-wallet {
        right: 20px;
    }

    *[dir="rtl"] .header-wallet {
        left: 20px !important;
    }
}

.searchButton {
    background-color: transparent;
    border: none;
    outline: transparent;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    width: fit-content;
    gap: 8px;
    border-radius: 8px;
    transition: all ease-in-out 200ms;
    padding: 12px;
    color: var(--primaryColor) !important;
}

.searchButton:hover {
    background-color: rgba(150, 150, 150, 0.16);
}

.searchButton .searchButtonIcon {
    transition: all ease-in-out 200ms;

}

.searchButton:hover .searchButtonIcon {
    margin-inline-start: 4px;
}

.clickable {
    cursor: pointer;
}

.note {
    font-size: 12px;
    font-weight: 400;
    color: #5c5f62;
    margin-block-start: 0;
    margin-block-end: 0;
}