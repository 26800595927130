.container {
    display: flex;
    justify-content: center;
    margin-block: 50px;
}

.container ul {
    opacity: 0.7;
}

.centeredCard {
    display: flex;
    flex-direction: row;
    gap: 16px;

}

.typeCard {
    padding: 16px;
    border: 1px solid #C0C0C0;
    border-radius: 4px;
    width: 100%;
    transition: background-color 100ms ease-in-out;
    cursor: pointer;
}

.typeCard header {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 16px;
}

.typeCard:hover {
    background: #F5F5F5;
}

.selected, .selected.typeCard:hover {
    background: #e0e0e0;
}