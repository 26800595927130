.ticketActions {

}

.ticketsCounter {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 32px 0 0;
}

.buttonActionContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 16px;
}

.scanStateCell svg, .menuItem svg {
    fill: var(--primaryColor);
}

.menuItem {
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
}