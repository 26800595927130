.container {
    margin-top: 60px;
}

.container h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #272727;
    margin-bottom: 42px;
}

.container ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

    gap: 24px;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.container li {
    min-width: 300px;
    flex: 1;
    max-width: 430px;

}

.card {
    gap: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #FFFFFF;
    font-size: 14px !important;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    font-weight: 400;
    width: 100%;
}

.activate, .deactivate {
    outline: none;
    font-size: 14px;
    border-radius: 4px;
    box-sizing: border-box;
}

.activate {
    background: var(--primaryColor);
    color: white;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.2);

    padding: 6px 16px;

    border: none;
}

.deactivate {
    background: transparent;
    border: 1px solid #cccfd2;
    padding: 5px 15px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
}