.message {
    --color-message: #e8e8e8;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-inline-start: 0;
    padding-inline-end: 24px;
}

.messageArrow {
    --arrow-size: 10px;
    height: fit-content;
    border-top: var(--arrow-size) solid var(--color-message);
    border-left: var(--arrow-size) solid transparent;
    border-right: var(--arrow-size) solid var(--color-message);
    margin-inline-end: -10px;
    position: relative;
}


.messageContent {
    background: var(--color-message);
    padding: 8px 16px;
    border-radius: 8px;
}

.message.me {
    padding-inline-start: 24px;
    padding-inline-end: 0;
    flex-direction: row-reverse;
    --color-message: #ddd3ec;
}


.message.me .messageArrow {
    border-top: var(--arrow-size) solid var(--color-message);
    border-left: var(--arrow-size) solid var(--color-message);
    border-right: var(--arrow-size) solid transparent;
    margin-inline-start: -10px;
}


.messageSend {
    display: flex;
    gap: 16px;
}

.messageSend > *:first-child {
    flex-grow: 1;
}


.messageSend button {
    align-self: flex-start;
    padding: 16px;
}

.messageSend button svg {
    width: 18px;
    height: 18px;
}