:root {
    --primaryColor:  rgb(92,85,165);
    --secondaryColor: rgb(92,85,165);
}

.addressCard {
    background: white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
}

.formTextField label {
    font-weight: normal !important;
    font-size: 14px;
}

label {
    font-weight: normal !important;
    font-size: 14px;
}

.bold-label {
    font-weight: bold;
}

.addressCard label {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #6d7175;
    margin-bottom: 15px;
}

.radio-txt {
    font-weight: normal;
    color: #202223;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;

}

.pageTitle {
    font-weight: bold !important;
    margin-bottom: 0px;
}

.MuiButtonBase-root.MuiTab-root {
    font-size: 20px !important;
    text-transform: unset !important;
    color: #5c5f62 !important;
}

.admin .MuiTab-textColorPrimary.Mui-selected {
    color: var(--primaryColor) !important;
    font-size: 20px;
}

.admin .MuiTabs-indicator {
    background-color: var(--primaryColor) !important;
}

.seller .MuiTab-textColorPrimary.Mui-selected {
    color: var(--secondaryColor) !important;
    font-size: 20px;
}

.seller .MuiTabs-indicator {
    background-color: var(--secondaryColor) !important;
}

.MuiTabs-flexContainer {
    justify-content: space-evenly;
}

.tabsCost {
    color: #5c5f62;
    margin-left: 10px;
}

.tabsInfoPrice {
    background: #ffffff;
    padding: 5px 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.calculateBtn,
.calculateBtn:focus {
    color: #fff !important;
    border: 1px solid var(--secondaryColor) !important;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--secondaryColor);
    padding: 4px 30px;
    font-size: 14px;
    margin-inline-end: 13px;
    font-weight: bold;
}

.calculateBtn:disabled {
    color: #fff !important;
    border: 1px solid #c0c0c0 !important;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #c0c0c0 !important;
    padding: 4px 30px;
    font-size: 14px;
}

.orderID {
    font-weight: 600;
    font-size: 36px;
    line-height: 100%;
    text-transform: capitalize;

    color: #5c5f62;
}

.buttons-margin {
    margin-top: 40px;
}

.line-margin {
    margin-top: 100px;
}

.info-text {
    font-size: 14px;
    color: #6d7175;
    font-weight: 300;
    font-family: SF Pro Text;
}

.address-container {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    padding: 10px 5px 20px;
    border-radius: 10px;
    background: transparent;
    margin-bottom: 20px;
}

.workModal .ant-input[disabled] {
    padding: 4px 30px !important;
}

.workModal {
    padding: 17px 0px;
}

.workModal .Polaris-Icon svg {
    width: 20px;
    height: 20px;
    position: absolute;
    margin-inline-start: 5px;
    top: 5px;
    z-index: 10;
    fill: rgba(92, 95, 98, 1);
}

.boldTitle {
    font-weight: bold;
}

.secTitle {
    color: rgba(109, 113, 117, 1);
}

.label-position {
    display: flex;
}

.label-row {
    align-self: end;
    flex-grow: 1;
}

.previous {
    margin-inline-end: 10px;
    border: 1px solid var(--primaryColor);
    border-radius: 4px;
    padding: 4px 30px;
    font-size: 14px;
    font-weight: bold;
    color: var(--primaryColor);
}

.previous:hover {
    background: var(--primaryColor);
    color: white;
}

.previous:disabled {
    color: #c0c0c0 !important;
    border: 1px solid #c0c0c0 !important;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: transparent !important;
    padding: 4px 15px;
    font-size: 14px;
    margin-inline-end: 13px;
}


.calculationRow {
    display: flex;
    flex-wrap: wrap;
    gap: 32px 60px;
    padding: 0;
    list-style: none;
    margin: 32px 0 0;
}

.calculationRow li {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.calculationRow .tabsInfoPrice {
    width: 150px;
    display: flex;
    justify-content: space-between;
}