.providers-list {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.provider-card {
    display: flex;
    flex-direction: column;
    max-width: 120px;
    text-align: center;
    padding: 12px;
    background-color: transparent;
    transition: all ease-in 150ms;
    box-shadow: transparent;
    cursor: pointer;
}

.provider-card:hover {
    background-color: #fcfcfc;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-color: var(--primaryColor);
}

.provider-card img {
    width: 100%;
    aspect-ratio: 1/1;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: #e1eaf1;

}

.provider-selected {
    background: #fcfcfc;
    border: 1px solid var(--primaryColor);
    border-radius: 8px;
}

.provider-selected:hover {
    box-shadow: unset;
}

.provider-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.provider-disabled:hover {
    background-color: transparent;
    border-radius: 0;
    box-shadow: unset;
}